import {useState} from "react";
import { useHistory } from "react-router-dom";


import {passwordReset} from "../../utils/firebase/auth";




import {ForgotPasswordForm, Container, Row, Column} from "../bootstrapComponents";


export default function ForgotPassword(){

    let history = useHistory();

    
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    
    
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async event =>{
        event.preventDefault();
        setSubmitting(true);
        setErrors({});

            try{

                await passwordReset(email);
    
                setSubmitting(false);
                setEmailSent(true);

                setTimeout(()=>history.push("/"), 5000);
                
            }catch(err){
    
                const {code, message} = err;
    
                
                setErrors({submit: {code, message}});
                
                setSubmitting(false);
            }

       

    }

    return(
        <Container>
            
            <Row
            extraClasses="justify-content-center"
            >
                <Column
                defColumn="col-md-6 p-4 border border-primary shadow-lg rounded-3"
                >
                    {
                        emailSent
                        ?
                        <p>A link to reset your password is sent to your email. Please check your inbox. You will be redirected to home page in a few seconds...</p>
                        :
                        <ForgotPasswordForm
                        email={email}
                        errors={errors}
                        submitting={submitting}
                        setEmail={setEmail}
                        onSubmit={onSubmit}
                        />

                    }
                
                    
                </Column>
            </Row>

        </Container>

    );
}