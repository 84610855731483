import {useEffect} from "react";

import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = (e) =>{
        e.preventDefault();
        email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          NAME: name.value
        });
    }

    useEffect(()=>{
      if(status==="success"){
        document.getElementById('subscribe-form').reset();
      }
    }, [status]);
     
  
    return (
        <form id="subscribe-form" className="row g-3" onSubmit={e=>submit(e)}>
            <div
            className="col-12 text-center"
            >
                <h5>Join the Mailing List</h5>

            </div>
      
       

        <div className="col-12">
        <label htmlFor="email" className="form-label">Email</label>
        <input  
        type="email" 
        className="form-control" 
        id="email" 
        placeholder="example@example.com"
        ref={node => (email = node)}
        required
        />
        </div>
        <div className="col-12">
        <label htmlFor="firstname" className="form-label">First Name</label>
        <input 
        type="text" 
        className="form-control" 
        id="firstname" 
        placeholder="Your Name"
        ref={node => (name = node)}
        required
        />
        </div>
       
        <div className="col-12 d-flex flex-column justify-content-center align-items-center">

        <button type="submit" className="btn btn-lightpink btn-lg w-50">
          Submit
        </button>
        </div>

        {status === "sending" && <div className="text-info text-center"  style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
          className="input-error text-center"
          dangerouslySetInnerHTML={{ __html: message }} 
          />
           
        )}
        {status === "success" && (
          <div
          className="text-success text-center"  
          >
            {message}
          </div>
        )}
      
      </form>
    );
  };

const SubscribeForm = ()=>(
    <MailchimpSubscribe
      url={'https://mrstutsmathclass.us6.list-manage.com/subscribe/post?u=782dc049552a02d833174e926&amp;id=10f06a50cd'}
      render={({ subscribe, status, message }) => (
        <div>
          <CustomForm 
            status={status}
            message={message} 
            onValidated={formData => subscribe(formData)} 
            />
          
        </div>
      )}
    />
  );

export default SubscribeForm;