import { Component } from "react";
import {writeData, getUID, readData, deleteData} from "../../../utils/firebase/database";
import {uploadFile, getDownloadUrl} from "../../../utils/firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import {Container, Row, Column, NewResourceForm, NewResourceCategory} from "../../bootstrapComponents";

export default class NewResource extends Component {

    state={
        title:'',
        description: '',
        url:'',
        type:'link',
        file: null,
        image: null,
        category:'',
        mainTopic: '',
        submitting: false,
        categoryName: '',
        oldCategoryName: '',
        categories: [],
        errors: {},
        categoryEditId: ''
    };

    componentDidMount(){
        this._fetchCategories();
    };

    _fetchCategories = async()=>{
        
        const categoriesRaw = await readData('resourceCategories').then(data=>data.val()).catch(err=>{
        console.log(err);
        
        });

        if(categoriesRaw){
            this.setState({categories: Object.values(categoriesRaw)})
        }

       
    }

    _handleNewCategory = async(event)=>{
        event.preventDefault();
        this.setState({
            submitting: true,
            errors: {}
        });

        const id = this.state.categoryEditId || getUID('resourceCategories');
        const name = this.state.categoryName.trim();

        if(name.length<3){
          return  this.setState({
                errors: {categoryName: "Find a nice category name"},
                submitting: false
            });
            
            
        }

        if(!this.state.mainTopic){
            return  this.setState({
                  errors: {mainTopic: "Select a topic"},
                  submitting: false
              });
              
              
          }

        if(this.state.categoryEditId &&  this.state.categories && this.state.categories.map(category=>category.value).includes(name.toLowerCase())){
            return  this.setState({
                errors: {categoryName: "This category already exists"},
                submitting: false
            });
        }

       try {

        await writeData('resourceCategories', id, {
            id,
            name,
            topic: this.state.mainTopic,
            value: name.toLowerCase(),
            // order: this.state.categories[this.state.categories.length-2].order+1
        });

        if(this.state.categoryEditId){

            this._updateResourceCategories(this.state.oldCategoryName.toLowerCase(), name.toLowerCase())
        }


        this.setState({
            oldCategoryName: '',
            categoryName: '',
            categoryEditId: '',
            mainTopic: '',
            submitting: false
        });

        this._fetchCategories();
           
       } catch (err) {
           this.setState({
                errors: {submit: err},
                submitting: false
            });
       }


    }
    

    _updateResourceCategories = async (oldValue='', newValue='')=>{

        const resourcesData = await readData('resources').then(data=>data.val()).catch(err=>{
            console.log(err);
            
            });

        const resourcesToUpdate = Object.values(resourcesData).filter(resource=>resource.category===oldValue);

       
            for (let index = 0; index < resourcesToUpdate.length; index++) {
                let resource = resourcesToUpdate[index];

                resource.category = newValue;

                
                
                try {

                    await writeData("resources", resource.id, {
                        ...resource
                    });
                    
                } catch (error) {
                    console.log(error);
                }
            }
        

        

    };

    _handleNewResource = async (event)=>{
        event.preventDefault();
        this.setState({
            submitting: true,
            errors: {}
        });

        const title = this.state.title.trim();
        const description = this.state.description.trim();
        const url = this.state.url.trim();
        const id = getUID("resources");

       

        if(title<3){
            return this.setState({
                errors: {title: "Need a nice title"},
                submitting: false
            })
        }

        if(description<3){
            return this.setState({
                errors: {description: "Need description"},
                submitting: false
            })
        }

        if(this.state.type==='link' && !this._isValidUrl(url)){
            return this.setState({
                errors: {url: "Invalid Url"},
                submitting: false
            }) 
        }

        if(!this.state.category){
            return this.setState({
                errors: {category: "Select one category"},
                submitting: false
            }) 
        }

        if(this.state.type==='file' && !this.state.file){
            return this.setState({
                errors: {file: "Please upload a file"},
                submitting: false
            }) 
        }

        if(!this.state.image || !this.state.image.type.includes('image')){
            console.log(this.state.image.type)
            return this.setState({
                errors: {image: "Please upload an image"},
                submitting: false
            }) 
        }

       



        try {

           

            const imagePath = `resource-images/resource${id}/${Date.now()}`;

            await uploadFile(this.state.image, imagePath);

            const imageUrl = await getDownloadUrl(imagePath);

            let fileUrl = null;
            let filePath = null;
            if(this.state.file){
                filePath = `resource-files/resource${id}/${this.state.file.name}`;

                await uploadFile(this.state.file, filePath);

                fileUrl = await getDownloadUrl(filePath);
            }

           

            await writeData("resources", id, {
                id,
                title,
                url,
                imageUrl,
                imagePath,
                fileUrl,
                filePath,
                description,
                type: this.state.type,
                category: this.state.category
            });

            this.setState({
                title:'',
                description: '',
                url:'',
                type:'link',
                category:'',
                image: null,
                submitting: false,
                errors: {}
            });

            document.getElementById('NewResourceForm').reset();
            
        } catch (err) {
            this.setState({
                errors: {submit: err},
                submitting: false
            })
        }


    }

    _isValidUrl = (value)=>{
        let url;
  
        try {
          url = new URL(value);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    }

    _deleteCategory = async (id)=>{

        try{

            await deleteData("resourceCategories", id);

            this._fetchCategories();

        }catch(err){

        }
    }

    _editCategory = category=>{
        this.setState({
            oldCategoryName: category.name,
            categoryName: category.name,
            mainTopic: category.topic,
            categoryEditId: category.id
        })
    }

    _cancelEdit=()=>{
        this.setState({
            oldCategoryName: '',
            categoryEditId: '',
            categoryName: '',
            mainTopic: '',
            errors: {}
        })
    }




    render(){

        const {title, url, type, category, submitting, categoryName, errors, categories, categoryEditId, mainTopic} = this.state;


        return(
            <Container>
                <Row extraClasses="justify-content-between">
                    <Column defColumn="col-4">
                        <NewResourceForm
                        title={title}
                        url={url}
                        type={type}
                        category={category}
                        submitting={submitting}
                        setTitle={(value)=>this.setState({title: value})}
                        setDescription={(value)=>this.setState({description: value})}
                        setUrl={(value)=>this.setState({url: value})}
                        setType={(value)=>this.setState({type: value})}
                        setImage={(image=>this.setState({image}))}
                        setFile={(file=>this.setState({file}))}
                        setCategory={(value)=>this.setState({category: value})}
                        onSubmit={this._handleNewResource}
                        errors={errors}
                        categories={categories}
                        />
                    </Column>
                    <Column defColumn="col-4">
                        <NewResourceCategory
                        categoryName={categoryName}
                        submitting={submitting}
                        setCategoryName={(value)=>this.setState({categoryName: value})}
                        setTopic={(value)=>this.setState({mainTopic: value})}
                        mainTopic={mainTopic}
                        onSubmit={this._handleNewCategory}
                        errors={errors}
                        editMode={!!categoryEditId}
                        cancelEdit={this._cancelEdit}
                        />

                       {
                           categories && categories.length
                           ?
                           <Row>
                           <h4 className="text-center mt-5" >Categories</h4>
                           {categories.map(category=><Column defColumn="col-6"> 
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>{category.name}</h6>
                                <h6 style={{whiteSpace: 'nowrap'}} >
                                <FontAwesomeIcon 
                                className="text-primary cursor-pointer" 
                                icon={faEdit}
                                onClick={()=>this._editCategory(category)}
                                />   
                                 
                                <FontAwesomeIcon 
                                className="text-danger cursor-pointer ms-1" 
                                icon={faTrashAlt}
                                onClick={()=>this._deleteCategory(category.id)}
                                />
                                </h6>
                            </div>
                            </Column>)}
                           </Row>
                           :
                           null
                       }

                    </Column>
                </Row>
            </Container>
        );
    };

};