import {Component} from "react";
import {Redirect} from 'react-router-dom';
import {groupBy} from "lodash";
import { readData, updateData, deleteData } from "../../utils/firebase/database";
import { deleteFile } from "../../utils/firebase/storage";

import firebase from "../../utils/firebase";


import SEO from "../seo";

import {Container, Row, Column, ResourceCard, ResourcesCategoryDropDown} from "../bootstrapComponents";

import Loading from "../loading";




class Resources extends Component {

    state ={
        categories: [],
        selectedCategory: {},
        resourcesByCategory: {},
        isLoading: true,
        redirectLink: ''
    };

   async componentDidMount(){
        
        this._fetchResources();
        
    }

    componentDidUpdate(){

        if(this.state.redirectLink){
            this.setState({redirectLink: ''});
        }

        let searchParams = new URLSearchParams(window.location.search);

        const queryCategory = searchParams.get('c');

        
        if(queryCategory){
            
           let querySelection = this.state.categories.find(catg => catg.id === searchParams.get('c'));
           if(querySelection && querySelection.id !== this.state.selectedCategory.id){
               this.setState({selectedCategory: querySelection});
           }
        }
    }

    _fetchResources = async(selectedCat)=>{
        let categories = await readData('resourceCategories').then(data=>data.val()).catch(err=>{
            console.log(err);
            
            });

        categories = Object.values(categories);

        const resourceData = await readData('resources').then(data=>data.val()).catch(err=>{
                console.log(err);
                
                });

        const resourcesByCategory = groupBy(resourceData,  'category');

        let filteredCategories = categories.filter(category=>resourcesByCategory[category.value] && resourcesByCategory[category.value].length);

        let searchParams = new URLSearchParams(window.location.search);

        let querySelection = null;

        if(searchParams.get('c')){
            querySelection = filteredCategories.find(catg => catg.id === searchParams.get('c'));
        }

        this.setState({
            categories: filteredCategories,
            selectedCategory: selectedCat || querySelection || filteredCategories[0],
            resourcesByCategory,
            isLoading: false
        });
    }

    _incrementClickCount = async (id)=>{
        let updates = {};

        updates[`resources/${id}/clickCount`] = firebase.database.ServerValue.increment(1);

       await updateData(updates);
    }

    

    _handleCategorySelection = async(category)=>{

        if(category.value===this.state.selectedCategory.value) return;

        this.setState({redirectLink: `/lessons?c=${category.id}`});

    }

    _handleResourceDelete = async(resource)=>{

        if(!this.props.isOwner) return;

        await deleteData('resources', resource.id);
        deleteFile(resource.imagePath);
        deleteFile(resource.filePath);

        this._fetchResources(this.state.selectedCategory);
    };



    render(){
        
        const {categories, resourcesByCategory, selectedCategory, isLoading, redirectLink} = this.state;

        if(redirectLink){
            return <Redirect to={redirectLink} />
        }

        
        return (
            <Container>
                <SEO
                title="Math Lessons"
                metaData={[
                    {
                        name: "description",
                        content:"Find fun and engaging math lessons for high school students"
                    },
                    {
                        name: "keywords",
                        content:"Math Lessons, High School Math"
                    }
                    
                ]}
                />
                <Row>
                    <Column
                    extraClasses="text-center mb-4"
                    >
                        <h1 className="page-header">Math Lessons</h1>
                    </Column>
                </Row>

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-md-none"
                    />
                    :
                    <Row
                    extraClasses="d-md-none"

                    >
                        <Column
                       
                        >
                            <ResourcesCategoryDropDown
                            categories={categories}
                            selectedCategory={selectedCategory}
                            selectCategory={this._handleCategorySelection}
                            />
                        </Column>
                    </Row>

                }

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-none d-md-block"
                    />
                    :
                    <Row
                    >
                    <Column
                    defColumn="col-2 d-none d-md-block"
                    >
                        <div
                        className="border border-dark px-2 w-100"
                        >
                            {
                               categories.filter(cat=>cat.topic==='algebra').length
                               ?
                                <>
                                    <h3 className="mb-3" >Algebra</h3>
                                    {
                                         categories.filter(cat=>cat.topic==='algebra').map((category, index)=><Row
                                         key={index+1}
                                         extraClasses={`${selectedCategory.value===category.value ? 'category-item-selected' : 'category-item'} py-1`}
                                         onClick={()=>this._handleCategorySelection(category)}
                                         >
                                             <Column
                                             extraClasses="align-items-center"
                                             >
                                                 <h6>{category.name}</h6>
                                             </Column>
                                         </Row>)
                                    }
                                </>
                               :
                               null
                            }

                            {
                               categories.filter(cat=>cat.topic==='geometry').length
                               ?
                                <>
                                    <h3 className="mb-3" >Geometry</h3>
                                    {
                                         categories.filter(cat=>cat.topic==='geometry').map((category, index)=><Row
                                         key={index+1}
                                         extraClasses={`${selectedCategory.value===category.value ? 'category-item-selected' : 'category-item'} py-1`}
                                         onClick={()=>this._handleCategorySelection(category)}
                                         >
                                             <Column
                                             extraClasses="align-items-center"
                                             >
                                                 <h6>{category.name}</h6>
                                             </Column>
                                         </Row>)
                                    }
                                </>
                               :
                               null
                            }

                            
                        </div>
                        
                    </Column>
                    <Column>
                        {
                            categories.length && isLoading
                            ?
                            <Loading
                            defColumn="col-md-10"
                            />
                            :
                            <Row 
                            extraClasses="justify-space-between gy-1"
                            >
                            {
                                resourcesByCategory[selectedCategory.value].map((resource, index)=>(
                                    <Column
                                    key={index+1}
                                    defColumn="col-md-4"
                                    extraClasses="mb-1"
                                    >
                                        <ResourceCard
                                        id={resource.id}
                                        imageUrl={resource.imageUrl}
                                        title={resource.title}
                                        description={resource.description}
                                        url={resource.type==='link' ? resource.url : resource.fileUrl}
                                        incrementClickCount={this._incrementClickCount}
                                        handleDelete={this._handleResourceDelete}
                                        isOwner={this.props.isOwner}
                                        clickCount={resource.clickCount || 0}
                                        urlType={resource.type}
                                        resource={resource}
                                        />
                                    </Column>
                                ))
                            }
                            </Row>

                        }
                        
                    </Column>
                    
                </Row>

                }

                
            </Container>
        );
    }

};
export default Resources;