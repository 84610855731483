import {Component} from "react";
import {Redirect} from 'react-router-dom';
import {groupBy} from "lodash";
import { readData } from "../../utils/firebase/database";


import SEO from "../seo";

import {Container, Row, Column, GameCard, GamesCategoryDropDown} from "../bootstrapComponents";

import Loading from "../loading";




class Games extends Component {

    state ={
        categories: [],
        selectedCategory: {},
        gamesByCategory: {},
        isLoading: true,
        redirectLink: ''
    };

   async componentDidMount(){
        let categories = await readData('gameCategories').then(data=>data.val()).catch(err=>{
            console.log(err);
            
            });

        categories = Object.values(categories);

        const gameData = await readData('games').then(data=>data.val()).catch(err=>{
                console.log(err);
                
                });

        const gamesByCategory = groupBy(gameData,  'category');

        let filteredCategories = categories.filter(category=>gamesByCategory[category.value] && gamesByCategory[category.value].length).sort((a,b)=>a.order-b.order);

        let searchParams = new URLSearchParams(window.location.search);

        let querySelection = null;

        if(searchParams.get('c')){
            querySelection = filteredCategories.find(catg => catg.id === searchParams.get('c'));
        }

        this.setState({
            categories: filteredCategories,
            selectedCategory: querySelection || filteredCategories[0]  ,
            gamesByCategory,
            isLoading: false
        })
        
    }

    componentDidUpdate(){

        if(this.state.redirectLink){
            this.setState({redirectLink: ''});
        }

        let searchParams = new URLSearchParams(window.location.search);

        const queryCategory = searchParams.get('c');

        
        if(queryCategory){
            
           let querySelection = this.state.categories.find(catg => catg.id === searchParams.get('c'));
           if(querySelection && querySelection.id !== this.state.selectedCategory.id){
               this.setState({selectedCategory: querySelection});
           }
        }
    }

    

    _handleCategorySelection = async(category)=>{

        if(category.id===this.state.selectedCategory.id) return;

        this.setState({redirectLink: `/games?c=${category.id}`});

    }



    render(){

        const {categories, gamesByCategory, selectedCategory, isLoading, redirectLink} = this.state;

        if(redirectLink){
            return <Redirect to={redirectLink} />
        }

        return (
            <Container>
                <SEO
                title="Math Games"
                metaData={[
                    {
                        name: "description",
                        content:"Find fun and engaging math games for middle school students"
                    },
                    {
                        name: "keywords",
                        content:"Math Games, Fun Games, Middle School Math"
                    }
                    
                ]}
                />
                <Row>
                    <Column
                    extraClasses="text-center mb-4"
                    >
                        <h1 className="page-header">Math Games</h1>
                    </Column>
                </Row>

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-md-none"
                    />
                    :
                    <Row
                    extraClasses="d-md-none"

                    >
                        <Column
                       
                        >
                            <GamesCategoryDropDown
                            categories={categories}
                            selectedCategory={selectedCategory}
                            selectCategory={this._handleCategorySelection}
                            />
                        </Column>
                    </Row>

                }

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-none d-md-block"
                    />
                    :
                    <Row
                    >
                    <Column
                    defColumn="col-2 d-none d-md-block"
                    >
                        <div
                        className="border border-dark px-2 w-100"
                        >
                        <h3 className="mb-3" >Categories</h3>

                            {
                                categories.map((category, index)=><Row
                                key={index+1}
                                extraClasses={`${selectedCategory.value===category.value ? 'category-item-selected' : 'category-item'} py-1`}
                                onClick={()=>this._handleCategorySelection(category)}
                                >
                                    <Column
                                    extraClasses="align-items-center"
                                    >
                                        <h6>{category.name}</h6>
                                    </Column>
                                </Row>)
                            }
                        </div>
                        
                    </Column>
                    <Column>
                        {
                            categories.length && isLoading
                            ?
                            <Loading
                            defColumn="col-md-10"
                            />
                            :
                            <Row 
                            extraClasses="justify-space-between gy-1"
                            >
                            {
                                gamesByCategory[selectedCategory.value].map((game, index)=>(
                                    <Column
                                    key={index+1}
                                    defColumn="col-md-4"
                                    extraClasses="mb-1"
                                    >
                                        <GameCard
                                        id={game.id}
                                        imageUrl={game.imageUrl}
                                        title={game.title}
                                        url={game.url}
                                        urlType={game.type}
                                        />
                                    </Column>
                                ))
                            }
                            </Row>

                        }
                        
                    </Column>
                    
                </Row>

                }

                
            </Container>
        );
    }

};
export default Games;