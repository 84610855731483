import {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';


import {getData, deleteData} from "../../utils/firebase/database";
import {Container, Row, Column, BlogDropDown} from "../bootstrapComponents";
import BodyEditor from "./new/blogEditor/reactDraft";

import Loading from "../loading";


export default class Blogs extends Component {

    state={
        title: null,
        author: null,
        date: null,
        body: null,
        redirectId: '',
        selectedBlog: {}
    };

    async componentDidMount(){
        const {match} = this.props;

        this.fetchBlogData(match.params.blogId, true);
       
    }


    async componentDidUpdate(prevProps, prevState){
        if(this.state.redirectId && prevState.redirectId!==this.state.redirectId){
            
            this.fetchBlogData(this.state.redirectId);
        }
    }

    fetchBlogData = async(blogId, initialFetch=false)=>{
        

        const blogData = await getData('blogs', blogId).then(snapshot=>{
            if (snapshot.exists()) {
                return snapshot.val();
              }
              else {
                
              }
        }).catch(err=>console.log(err));

       
        this.setState({
            title: blogData.title,
            author: blogData.author,
            date: blogData.date,
            body: blogData.body,
            language: blogData.language,
            redirectId: '',
            selectedBlog: initialFetch ? {} : blogData
        });
       
    };

    _deleteBlog = async()=>{
        const {history, match, fetchBlogsData} = this.props;

        if(window.confirm('Do you want to delete this blog?')){

            // await deleteFile(`blog-images/blog${match.params.blogId}`);
            await deleteData('blogs', match.params.blogId);
            fetchBlogsData();
            return history.push('/');
        }

    }

    _editBlog = ()=>{
        const {history, match} = this.props;

        return history.push(`/editblog/${match.params.blogId}`);
    }

    
   

    render(){


        const {match, isOwner, englishBlogs, turkishBlogs}=this.props;
        const {title, author, date, body, redirectId, language, selectedBlog}=this.state;

        if(redirectId){
            return <Redirect to={`/blog/${redirectId}`} />
        }

        if(!body) return <Loading/>
        
        return(
           <Container>
               <Row
                extraClasses="d-md-none"

                >
                    <Column
                            
                    >
                        <BlogDropDown
                        defaultTitle="Blogs"
                        blogs={englishBlogs}
                        selectedBlog={selectedBlog}
                        selectBlog={(blog)=>this.setState({redirectId: blog.id})}
                        />
                    </Column>
                </Row> 

                <Row
                extraClasses="d-md-none"

                >
                    <Column
                            
                    >
                        <BlogDropDown
                        defaultTitle="Yazılar"
                        blogs={turkishBlogs}
                        selectedBlog={selectedBlog}
                        selectBlog={(blog)=>this.setState({redirectId: blog.id})}
                        />
                    </Column>
                </Row>

               <Row
                    >
                    <Column
                    defColumn="col-2 d-none d-md-block"
                    >
                        {
                            englishBlogs && englishBlogs.length
                            ?
                            <div
                            className="border border-dark px-2 w-100"
                            >
                            <h3 className="mb-3" >Blogs</h3>

                            

                                {
                                    englishBlogs.map((blog, index)=><Row
                                    key={index+1}
                                    extraClasses={`${match.params.blogId === blog.id ? 'category-item-selected' : 'category-item'} py-1`}
                                    onClick={()=>this.setState({redirectId: blog.id})}
                                    >
                                        <Column
                                        extraClasses="align-items-center"
                                        >
                                            <h6 >{blog.title}</h6>
                                        </Column>
                                    </Row>)
                                }
                            </div>
                            :
                            null
                        }

                        {
                            turkishBlogs && turkishBlogs.length
                            ?
                            <div
                            className="border border-dark px-2 w-100 mt-3"
                            >
                            <h3 className="mb-3" >Yazılar</h3>

                                {
                                    turkishBlogs.map((blog, index)=><Row
                                    key={englishBlogs.length+index}
                                    extraClasses={`${match.params.blogId === blog.id ? 'category-item-selected' : 'category-item'} py-1`}
                                    onClick={()=>this.setState({redirectId: blog.id})}
                                    >
                                        <Column
                                        extraClasses="align-items-center"
                                        >
                                            <h6 >{blog.title}</h6>
                                        </Column>
                                    </Row>)
                                }
                            </div>
                            :
                            null
                        }

                        
                        
                    </Column>

                   
                    <BlogTemplate
                    id={match.params.blogId}
                    isOwner={isOwner}
                    title={title}
                    author={author}
                    language={language}
                    date={date}
                    body={JSON.parse(body)}
                    deleteBlog={this._deleteBlog}
                    editBlog={this._editBlog}
                    />
                    
                </Row>

           </Container>
        );
    }
};

function BlogTemplate({title, date, author, body, language, isOwner, deleteBlog, editBlog}){


    return(
                    <Column 
                    defColumn="col-10"
                    >
                        <div className="blogTop">

                            <h1 className="blogTitle">{title}</h1>

                            
                            <p>By <strong><i>{author}</i></strong></p>
                            <p>{formatDate(date, language)}</p>

                            {
                            isOwner
                            ?
                            <div className="d-flex">

                                <button type="button" className="btn btn-secondary" onClick={()=>editBlog()} ><FontAwesomeIcon icon={faEdit} /> Edit</button>

                                <button type="button" className="btn btn-danger ms-3" onClick={()=>deleteBlog()} ><FontAwesomeIcon icon={faTrashAlt} /> Delete</button>
                            
                            </div>
                            :
                            null
                        }
                            
                        </div>

                        

                        <BodyEditor
                        readOnly={true}
                        initialContent={body}
                        />
                    
                    </Column>
    )
}

function formatDate(date, language){
    const options ={ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    return new Intl.DateTimeFormat(language==='turkish' ? 'tr-TR' : 'en-US', options).format(date);
}