import {auth} from './index';

export const signUpUser = (email='', password='')=>{

    return auth.createUserWithEmailAndPassword(email, password);

    
};

export const signInUser = async(email='', password='')=>{

  return auth.signInWithEmailAndPassword(email, password);

}



export  const authObserver = (changeHandle)=>{
  return  auth.onAuthStateChanged(changeHandle);

  // function(user) {
  //   if (user) {
  //     // User is signed in.
  //     console.log({user}, 'authibserv')
  //   } else {
  //     // No user is signed in.
  //     console.log('the user not signed in')
  //   }
  // }
};

export const getCurrentUser = ()=> auth.currentUser;

export const updateUser = (payload={})=>{

    getCurrentUser.updateProfile(payload).then(function() {
        // Update successful.
      }).catch(function(error) {
        // An error happened.
      });

};

export const updateEmail = email =>{
    getCurrentUser.updateEmail(email).then(function() {
        // Update successful.
      }).catch(function(error) {
        // An error happened.
      });
};

export const sendVerificationEmail = ()=>{
    getCurrentUser.sendEmailVerification().then(function() {
        // Email sent.
      }).catch(function(error) {
        // An error happened.
      });
};

export const updatePassword = (newPassword)=>{
    getCurrentUser.updatePassword(newPassword).then(function() {
        // Update successful.
      }).catch(function(error) {
        // An error happened.
      });
};

export const passwordReset = email=>{
  return  auth.sendPasswordResetEmail(email);
};

export const signOut = ()=>{

    auth.signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
}