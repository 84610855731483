import { Component } from "react";
import {writeData, getUID, readData, deleteData} from "../../../utils/firebase/database";
import {uploadFile, getDownloadUrl} from "../../../utils/firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import {Container, Row, Column, NewGameForm, NewGameCategory} from "../../bootstrapComponents";

export default class NewGame extends Component {

    state={
        title:'',
        url:'',
        type:'link',
        image: null,
        category:'',
        submitting: false,
        categoryName: '',
        oldCategoryName: '',
        categories: [],
        errors: {},
        categoryEditId: ''
    };

    componentDidMount(){
        this._fetchCategories();
    };

    _fetchCategories = async()=>{
        
        const categoriesRaw = await readData('gameCategories').then(data=>data.val()).catch(err=>{
        console.log(err);
        
        });

        if(categoriesRaw){
            this.setState({categories: Object.values(categoriesRaw).sort((a,b)=>a.order-b.order)})
        }

       
    }

    _handleNewCategory = async(event)=>{
        event.preventDefault();
        this.setState({
            submitting: true,
            errors: {}
        });

        const id = this.state.categoryEditId || getUID('gameCategories');
        const name = this.state.categoryName.trim();

        if(name.length<3){
          return  this.setState({
                errors: {categoryName: "Find a nice category name"},
                submitting: false
            });
            
            
        }

        if(this.state.categoryEditId &&  this.state.categories && this.state.categories.map(category=>category.value).includes(name.toLowerCase())){
            return  this.setState({
                errors: {categoryName: "This category already exists"},
                submitting: false
            });
        }

       try {

        await writeData('gameCategories', id, {
            id,
            name,
            value: name.toLowerCase(),
            order: this.state.categories[this.state.categories.length-2].order+1
        });

        if(this.state.categoryEditId){

            this._updateGameCategories(this.state.oldCategoryName.toLowerCase(), name.toLowerCase())
        }


        this.setState({
            oldCategoryName: '',
            categoryName: '',
            categoryEditId: '',
            submitting: false
        });

        this._fetchCategories();
           
       } catch (err) {
           this.setState({
                errors: {submit: err},
                submitting: false
            });
       }


    }
    

    _updateGameCategories = async (oldValue='', newValue='')=>{

        const gamesData = await readData('games').then(data=>data.val()).catch(err=>{
            console.log(err);
            
            });

        const gamesToUpdate = Object.values(gamesData).filter(game=>game.category===oldValue);

       
            for (let index = 0; index < gamesToUpdate.length; index++) {
                let game = gamesToUpdate[index];

                game.category = newValue;

                
                
                try {

                    await writeData("games", game.id, {
                        ...game
                    });
                    
                } catch (error) {
                    console.log(error);
                }
            }
        

        

    };

    _handleNewGame = async (event)=>{
        event.preventDefault();
        this.setState({
            submitting: true,
            errors: {}
        });

        const title = this.state.title.trim();
        const url = this.state.url.trim();
        const id = getUID("games");

       

        if(title<3){
            return this.setState({
                errors: {title: "Need a nice title"},
                submitting: false
            })
        }

        if(!this._isValidUrl(url)){
            return this.setState({
                errors: {url: "Invalid Url"},
                submitting: false
            }) 
        }

        if(!this.state.category){
            return this.setState({
                errors: {category: "Select one category"},
                submitting: false
            }) 
        }

        if(!this.state.image || !this.state.image.type.includes('image')){
            console.log(this.state.image.type)
            return this.setState({
                errors: {image: "Please upload an image"},
                submitting: false
            }) 
        }

       



        try {

            const imagePath = `game-images/game${id}/${Date.now()}`;

            await uploadFile(this.state.image, imagePath);

            const imageUrl = await getDownloadUrl(imagePath);

           

            await writeData("games", id, {
                id,
                title,
                url,
                imageUrl,
                type: this.state.type,
                category: this.state.category
            });

            this.setState({
                title:'',
                url:'',
                type:'link',
                category:'',
                image: null,
                submitting: false,
                errors: {}
            });

            document.getElementById('NewGameForm').reset();
            
        } catch (err) {
            this.setState({
                errors: {submit: err},
                submitting: false
            })
        }


    }

    _isValidUrl = (value)=>{
        let url;
  
        try {
          url = new URL(value);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    }

    _deleteCategory = async (id)=>{

        try{

            await deleteData("gameCategories", id);

            this._fetchCategories();

        }catch(err){

        }
    }

    _editCategory = category=>{
        this.setState({
            oldCategoryName: category.name,
            categoryName: category.name,
            categoryEditId: category.id
        })
    }

    _cancelEdit=()=>{
        this.setState({
            oldCategoryName: '',
            categoryEditId: '',
            categoryName: '',
            errors: {}
        })
    }




    render(){

        const {title, url, type, category, submitting, categoryName, errors, categories, categoryEditId} = this.state;


        return(
            <Container>
                <Row extraClasses="justify-content-between">
                    <Column defColumn="col-4">
                        <NewGameForm
                        title={title}
                        url={url}
                        type={type}
                        category={category}
                        submitting={submitting}
                        setTitle={(value)=>this.setState({title: value})}
                        setUrl={(value)=>this.setState({url: value})}
                        setType={(value)=>this.setState({type: value})}
                        setImage={(image=>this.setState({image}))}
                        setCategory={(value)=>this.setState({category: value})}
                        onSubmit={this._handleNewGame}
                        errors={errors}
                        categories={categories}
                        />
                    </Column>
                    <Column defColumn="col-4">
                        <NewGameCategory
                        categoryName={categoryName}
                        submitting={submitting}
                        setCategoryName={(value)=>this.setState({categoryName: value})}
                        onSubmit={this._handleNewCategory}
                        errors={errors}
                        editMode={!!categoryEditId}
                        cancelEdit={this._cancelEdit}
                        />

                       {
                           categories && categories.length
                           ?
                           <Row>
                           <h4 className="text-center mt-5" >Categories</h4>
                           {categories.map(category=><Column defColumn="col-6"> 
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>{category.name}</h6>
                                <h6 style={{whiteSpace: 'nowrap'}} >
                                <FontAwesomeIcon 
                                className="text-primary cursor-pointer" 
                                icon={faEdit}
                                onClick={()=>this._editCategory(category)}
                                />   
                                 
                                <FontAwesomeIcon 
                                className="text-danger cursor-pointer ms-1" 
                                icon={faTrashAlt}
                                onClick={()=>this._deleteCategory(category.id)}
                                />
                                </h6>
                            </div>
                            </Column>)}
                           </Row>
                           :
                           null
                       }

                    </Column>
                </Row>
            </Container>
        );
    };

};