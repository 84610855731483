import {useState} from "react";
import { useHistory, Link } from "react-router-dom";


import {signInUser} from "../../utils/firebase/auth";




import {LogInForm, Container, Row, Column} from "../bootstrapComponents";


export default function LogIn(){

    let history = useHistory();

    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async event =>{
        event.preventDefault();
        setSubmitting(true);
        setErrors({});

        

            try{
                await signInUser(email, password);
    

              
                setSubmitting(false);
                return history.push('/');
                
            }catch(err){
    
                const {code, message} = err;
    
                setErrors({submit: {code, message}});
                
    
            }

           
        

        setSubmitting(false);

    }


    return(
        <Container>
            
            <Row
            extraClasses="justify-content-center"
            >
                <Column
                defColumn="col-md-6 p-4 border border-primary shadow-lg rounded-3"
                >
                
                    <LogInForm
                    email={email}
                    password={password}
                    errors={errors}
                    submitting={submitting}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    onSubmit={onSubmit}
                    />
                </Column>
            </Row>

            <Row
            extraClasses="justify-content-center mt-2"
            >
                <Column
                extraClasses="text-center"
                >
                    <h5>Don't you have an account?</h5>
                    <Link
                    to="/signup"
                    className="fs-5 fw-bolder"
                    >
                        Sign Up
                    </Link>

                </Column>
            </Row>
        </Container>

    );
}