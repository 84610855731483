import {Component} from "react";
import {Redirect} from "react-router-dom";
import NewBlog from "./blogEditor";
import {writeData, getUID, getData} from "../../../utils/firebase/database"

export default class Blog extends Component {

    state={
        blogId: getUID('blogs'),
        editMode: false,
        title:'',
        language: 'english',
        errors:{},
        submitting: false,
        redirect:'',
        date: '',
        body: '',
        initialContent:''
    }

    componentDidMount(){
        const {match} = this.props;

        if( match && match.params && match.params.blogId){
            this.fetchEditData(match.params.blogId);
        }

    }

    

    fetchEditData = async(id)=>{

        const data = await getData('blogs', id).then(snapshot=>{
            if (snapshot.exists()) {
                return snapshot.val();
              }
              else {
                
              }
        }).catch(err=>console.log(err));

        console.log(data, 'EDITDATA')
        if(data){

            this.setState({
                blogId: id,
                editMode: true,
                initialContent: JSON.parse(data.body),
                title: data.title,
                language: data.language,
                date: data.date
            })
           
        }

    };

    


    onSubmit = async e =>{
        e.preventDefault();
        
        const {authUser, fetchBlogsData} = this.props;
        const {title, body, language, date, blogId} = this.state;

        this.setState({
            errors: {},
            submitting: true
        })

        if(!title || title.trim().length<3){
            return this.setState({
                errors: {title:'A nice title required'},
                submitting: false
            });
           
        }

        if(!body){
            return this.setState({
                errors: {body:'You forgot something...'},
                submitting: false
            });
            
        }

        try{

            const payload = {
                title,
                body: JSON.stringify(body),
                language,
                author: authUser.firstName+" "+authUser.lastName,
                date: date || Date.now()
            };
            await writeData('blogs', blogId, payload);
            fetchBlogsData();
            this.setState({
                submitting: false,
                redirect:`/blog/${blogId}`
            })
            


        }catch(err){
            this.setState({
                errors: {submit: err},
                submitting: false
            });
            
        }

    }


    render(){
        const {match} = this.props;
        const {title, body, language, blogId, errors, submitting, editMode, initialContent, redirect} = this.state;

        if(redirect){
            return <Redirect to={redirect} />
        }

        if(match && match.params.blogId && !initialContent) return null;
        
        return (
            <div className="container">
                <h2 className="text-center"> {editMode?'EDIT' : 'NEW'} BLOG POST</h2>
                <NewBlog
                title={title}
                body={body}
                language={language}
                setTitle={value=>this.setState({title: value})}
                setBody={value=>this.setState({body: value})}
                setLanguage={value=>this.setState({language: value})}
                onSubmit={this.onSubmit}
                blogId={blogId}
                errors={errors}
                submitting={submitting}
                editMode={editMode}
                initialContent={initialContent || ''}
                />
            </div>
        )
    }

    
}