import {Component} from "react";
import SEO from "../seo";
import { getTptCategories, getTptProducts} from "../../utils/tpt";

import {Container, Row, Column, Card, CategoryDropDown} from "../bootstrapComponents";

import Loading from "../loading";

const TptBaseUrl = "https://www.teacherspayteachers.com";

class TptStore extends Component {

    state ={
        categories: [],
        products: [],
        selectedCategory: {},
        isLoading: true
    };

    componentDidMount(){
        this._fecthTptData();
    }

    _fecthTptData = async()=>{
        const categories = await getTptCategories();
        const products = await getTptProducts();
        this.setState({categories, products, isLoading: false});
    }

    _handleCategorySelection = async(category)=>{

        if(category.link===this.state.selectedCategory.link) return;

        this.setState({selectedCategory: category, isLoading: true});

        const products = await getTptProducts(category.link);

        this.setState({products, isLoading: false});
    }



    render(){

        const {categories, products, selectedCategory, isLoading} = this.state;

        return (
            <Container>
                <SEO
                title="TPT Store"
                metaData={[
                    {
                        name: "description",
                        content:"Find fun and engaging math activities for middle school students"
                    },
                    {
                        name: "keywords",
                        content:"Math Activities, Math Worksheets, Middle School Math, Teachers Pay Teachers"
                    }
                    
                ]}
                />
                <Row>
                    <Column
                    extraClasses="text-center mb-4"
                    >
                        
                        <h1 className="page-header" >TPT STORE</h1>
                    </Column>
                </Row>

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-md-none"
                    />
                    :
                    <Row
                    extraClasses="d-md-none"

                    >
                        <Column
                       
                        >
                            <CategoryDropDown
                            categories={categories}
                            selectedCategory={selectedCategory}
                            selectCategory={this._handleCategorySelection}
                            />
                        </Column>
                    </Row>

                }

                {
                    categories.length<1 && isLoading
                    ?
                    <Loading
                    extraClasses="d-none d-md-block"
                    />
                    :
                    <Row
                    >
                    <Column
                    defColumn="col-2 d-none d-md-block"
                    >
                        <div
                        className="border border-dark px-2 w-100"
                        >
                        <h3 className="mb-3" >Categories</h3>

                            {
                                categories.map((category, index)=><Row
                                key={index+1}
                                extraClasses={`${selectedCategory.link===category.link ? 'category-item-selected' : 'category-item'} py-1`}
                                onClick={()=>this._handleCategorySelection(category)}
                                >
                                    <Column
                                    extraClasses="align-items-center"
                                    >
                                        <h6>{category.title}</h6>
                                    </Column>
                                </Row>)
                            }
                        </div>
                        
                    </Column>
                    <Column>
                        {
                            categories.length && isLoading
                            ?
                            <Loading
                            defColumn="col-md-10"
                            />
                            :
                            <Row 
                            extraClasses="justify-space-between gy-1"
                            >
                            {
                                products.map((product, index)=>(
                                    <Column
                                    key={index+1}
                                    defColumn="col-md-4"
                                    extraClasses="mb-1"
                                    >
                                        <Card
                                        image={product.image}
                                        title={product.title}
                                        description={product.description}
                                        link={TptBaseUrl+product.link}
                                        />
                                    </Column>
                                ))
                            }
                            </Row>

                        }
                        
                    </Column>
                    
                </Row>

                }

                
            </Container>
        );
    }

};
export default TptStore;