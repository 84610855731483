import { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faSignOutAlt, faFeatherAlt, faHeart, faMugHot, faTrophy, faSchool } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faTwitterSquare, faFacebookSquare, faPinterestSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';


import './App.css';

import SEO from "./components/seo";

import {signOut} from "./utils/firebase/auth";
import {readData} from "./utils/firebase/database";
import Home from "./components/home";
import TptStore from "./components/tpt-store";
import SignUp from "./components/signup";
import LogIn from "./components/login";
import ForgotPassword from "./components/forgotpassword";
import NewBlog from "./components/blog/new";
import Blog from "./components/blog";

import Games from "./components/games";
import NewGame from "./components/games/new";
import PlayGame from "./components/games/play";


import Resources from "./components/resources";
import NewResource from "./components/resources/new";

import SeoImage from "./images/nav-logo.png"


class App extends Component {

  state={
    englishSelected: true,
    englishBlogs: null,
    turkishBlogs: null
  }

  async componentDidMount(){
     this._fetchBlogsData();
  }

  _fetchBlogsData = async()=>{
    const blogsData = await readData('blogs').then(data=>data.val()).catch(err=>{
      console.log(err);
      
      });

      let blogs=[];
    if(blogsData){
      
      for(const [id, data] of Object.entries(blogsData)){
        const {title, author, date, language} = data; 
        blogs.push({
            id,
            title,
            author,
            date,
            language
        })
    };
    }

       blogs = blogs.sort((a,b)=>b.date-a.date);
        const englishBlogs= blogs.filter(blog=>blog.language==="english");
        const turkishBlogs= blogs.filter(blog=>blog.language!=="english");
       this.setState({
           englishBlogs,
           turkishBlogs
       })
  };

 render(){

  const {authUser} = this.props;
  const {englishBlogs, turkishBlogs, englishSelected} = this.state;
  const isOwner = authUser && authUser.uid === process.env.REACT_APP_ADMIN_ID;
  if(!englishBlogs) return null;
  return (
    <Router>
      <SEO
      title="Welcome"
      metaData={[
        {
            name: "description",
            content:"Mrs. Tut's Math Class is creating highly engaging educational Math activities and worksheets which are available for sale on Teachers Pay Teachers, and sharing her insights and experiences from time to time on her blog."
        },
        {
            name: "keywords",
            content:"Math Activities, Math Worksheets, Middle School Math, High School Math, Teachers Pay Teachers"
        }
        
      ]}

      socialData={[
        {
            property: "og:title",
            content:`Mrs. Tut's Math Class`
        },
        {
          property: "og:description",
          content:`Mrs. Tut's Math Class is creating highly engaging educational Math activities and worksheets which are available for sale on Teachers Pay Teachers, and sharing her insights and experiences from time to time on her blog.`
      },
      {
        property: "og:site_name",
        content:`Mrs. Tut's Math Class`
      },
        {
            property: "og:image",
            content:`${SeoImage}`
        }
        
    ]}
      />
      <div className="d-flex flex-column min-vh-100">
        

        <NavBar isOwner={isOwner} authUser={authUser} initialBlogId={englishSelected &&  englishBlogs[0]  ? englishBlogs[0].id  : turkishBlogs[0] ? turkishBlogs[0].id : '' } />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route 
          exact
          path="/blog/:blogId"
          component={(props)=><Blog
            isOwner={isOwner}
            blogs={englishSelected ? englishBlogs : turkishBlogs}
            englishBlogs={englishBlogs}
            turkishBlogs={turkishBlogs}
            englishSelected={englishSelected} 
            toggleLanguage={()=>this.setState({englishSelected: !englishSelected})} 
            fetchBlogsData={this._fetchBlogsData}
            {...props} />}
          />

          <Route 
          exact
          path="/games/:gameId"
          component={(props)=><PlayGame {...props} />}
          />
            

          {
            //only available to admin user
            isOwner
            ?
            <Route exact path="/newblog">
              <NewBlog authUser={authUser} fetchBlogsData={this._fetchBlogsData} />
            </Route>
            :
            null
          }

          {
            //only available to admin user
            isOwner
            ?
            <Route 
            exact 
            path="/editblog/:blogId"
            component={(props)=><NewBlog 
              authUser={authUser} 
              fetchBlogsData={this._fetchBlogsData}  
              {...props}
              />}
            />
             
            :
            null
          }

          {/* {
            //only available to admin user
            isOwner
            ?
            <Route exact path="/profile">
              <Profile authUser={authUser} />
            </Route>
            :
            null
          } */}

          {
            //only available to admin user
            isOwner
            ?
            <Route exact path="/newgame">
              <NewGame authUser={authUser} />
            </Route>
            :
            null
          }

          {
            //only available to admin user
            isOwner
            ?
            <Route exact path="/newresource">
              <NewResource authUser={authUser} />
            </Route>
            :
            null
          }

          <Route exact path="/signup">
            <SignUp/>
          </Route>

          <Route exact path="/login">
            <LogIn/>
          </Route>

          <Route exact path="/forgot-password">
            <ForgotPassword/>
          </Route>

          <Route exact path="/games">
            <Games/>
          </Route>

          <Route exact path="/lessons">
            <Resources isOwner={isOwner} />
          </Route>

          <Route exact path="/tpt-store">
            <TptStore/>
          </Route>
          <Route exact path="/">
           <Home initialBlogId={englishSelected &&  englishBlogs[0]  ? englishBlogs[0].id  : turkishBlogs[0] ? turkishBlogs[0].id : '' } />
          </Route>

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <div style={{height: '50px'}} />
        <Footer/>
      </div>
    </Router>
  );
 }
}

export default App;


const Footer = ()=>(
 
<footer className="footer mt-auto py-5 bg-light">
  <div className="container">
      <div className="d-flex flex-column align-items-center">
      <i className="fs-4" >Follow Us on Social Media</i>
      <SocialMedia color='#756a6a' extraClasses="fs-2 mb-3" />
      <i className="mb-0">Made with lots of <FontAwesomeIcon icon={faHeart} color="#cf0a38" /> & <FontAwesomeIcon icon={faMugHot} color="#6f4e37 " /> by <a className="text-reset" href="https://www.linkedin.com/in/mustafa-ay-dev/" target="_blank" rel="noreferrer">Mustafa Ay</a></i>
      <p>&#169; {new Date().getFullYear()}</p>
    </div>
  </div>
</footer>

);

const NavBar = (props)=>{

  let location = useLocation();
  const options = [
    {
      name: "Home",
      path: "/"
    },
    {
      name: "Blog",
      path: `/blog/${props.initialBlogId}`
    },
    {
      name: "TPT Store",
      path: "/tpt-store"
    },
    {
      name: "Games",
      path: "/games"
    },
    {
      name: "Lessons",
      path: "/lessons"
    },
    // {
    //   name: "Log In",
    //   path: "/login"
    // },
    // {
    //   name: "Sign Up",
    //   path: "/signup",
      
    // }
  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-lightpink mb-5 sticky-top">
  <div className="container">
    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
       
        {options.map((option, index)=> <li key={index+1} className="nav-item">
          <Link to={option.customPath || option.path} className={`nav-link fw-bold fs-2 ${(option.name==="Blog" && location.pathname.includes('blog')) || option.path===location.pathname ? "active" : ""}`} aria-current={option.path===location.pathname} >{option.name}</Link>
        </li>)}
       
       
      </ul>
      <div className="d-flex mb-3 mb-lg-0 me-5">

        {
          props.authUser && props.authUser.firstName
          ?
          // <>
          //   <span className="navbar-text text-white me-3">
          //     {`Hello, ${props.authUser.firstName}`}
          //   </span>
          //  <button className="btn btn-danger" onClick={()=>signOut()}><FontAwesomeIcon size="lg" icon={faSignOutAlt} /> SIGN OUT</button>
          // </>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a className="nav-link text-white dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="px-2 fw-bold"  >{`Hello, ${props.authUser.firstName}`}</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <li><Link className="dropdown-item" to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li> */}
                {
                  props.isOwner
                  ?
                  <li><Link className="dropdown-item" to="/newblog"><FontAwesomeIcon icon={faFeatherAlt} /> Write Blog</Link></li>
                  :
                  null
                }
                {
                  props.isOwner
                  ?
                  <li><Link className="dropdown-item" to="/newgame"><FontAwesomeIcon icon={faTrophy} /> Add Game</Link></li>
                  :
                  null
                }
                {
                  props.isOwner
                  ?
                  <li><Link className="dropdown-item" to="/newresource"><FontAwesomeIcon icon={faSchool} /> Add Lesson</Link></li>
                  :
                  null
                }
                <li><hr className="dropdown-divider"/></li>
                <li><button className="dropdown-item" onClick={()=>signOut()} ><FontAwesomeIcon icon={faSignOutAlt} /> Log Out</button></li>
              </ul>
            </li>
          </ul>
          :
          null
          // <ul>
          //   <Link to="/login" className="btn btn-success me-2"><FontAwesomeIcon size="lg" icon={faSignInAlt} /> LOG IN</Link>
          //   <Link to="/signup" className="btn btn-light"><FontAwesomeIcon size="lg" icon={faUserPlus} /> SIGN UP</Link>
          // </ul>
        }
      </div>
    </div>
    
        {
          props.isOwner
          ?
          null
          :
          <SocialMedia/>
        }
    
  </div>
  
</nav>
  );
}

const SocialMedia = props=>{

  const accounts = [
    {
      name: 'TPT Store',
      link: `https://www.teacherspayteachers.com/Store/${process.env.REACT_APP_TPT_STORE_NAME}`,
      icon: faStore
    },
    {
      name: 'Instagram',
      link: process.env.REACT_APP_INSTAGRAM_LINK,
      icon: faInstagram
    },
    {
      name: 'Pinterest',
      link: process.env.REACT_APP_PINTEREST_LINK,
      icon: faPinterestSquare
    },
    {
      name: 'Facebook',
      link: process.env.REACT_APP_FACEBOOK_LINK,
      icon: faFacebookSquare
    },
    {
      name: 'Twitter',
      link: process.env.REACT_APP_TWITTER_LINK,
      icon: faTwitterSquare
    },
    {
      name: 'Linkedin',
      link: process.env.REACT_APP_LINKEDIN_LINK,
      icon: faLinkedin
    },
    {
      name: 'Email',
      link: process.env.REACT_APP_EMAIL,
      icon: faEnvelope
    },
    
  ]

  return (
    <div className={props.extraClasses || "position-absolute end-0"} >
      {
        accounts.filter(account=>account.link).map((account, index)=><SocialMediaLink key={index+1} icon={account.icon} color={props.color} link={account.link} name={account.name} />)
      }
    </div>
  );
}

const SocialMediaLink = ({icon, link, name, color})=>(
  <a href={link} title={name} target="_blank" rel="noreferrer">
    {/* <i className={`bi bi-${'store'} text-white fs-1 me-3`}></i> */}
    <FontAwesomeIcon icon={icon} color={color || 'white'} className="fs-1 me-3" />
  </a>
);