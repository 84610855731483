import {Column, Row} from "../bootstrapComponents";


import Logo from "../../images/nav-logo.png";


export default function Loading(props){

    return(
        <Row
        extraClasses={`pt-5 ${props.extraClasses || ""}`}
        >
        <Column
        defColumn={props.defColumn || 'col'}
        extraClasses="text-center"
        >
            <img src={Logo} className="rounded mx-auto d-block spinning-logo" width="200" alt="Spinning Logo" />
            <h2>Loading...</h2>
        </Column>
        </Row>
    );
};