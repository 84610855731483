import {useState} from "react";
import { useHistory, Link } from "react-router-dom";


import {signUpUser} from "../../utils/firebase/auth";
import {writeData} from "../../utils/firebase/database";




import {SignUpForm, Container, Row, Column} from "../bootstrapComponents";


export default function SignUp(){

    let history = useHistory();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async event =>{
        event.preventDefault();
        setSubmitting(true);
        setErrors({});

        if(password !== confirmPassword){
           setErrors({
                password: 'Passwords do not match.'
            })
        }else if(password.length<6){
          setErrors({
                password: 'Password should be at least 6 characters long.'
            })
        }else{

            try{
                const userCredential = await signUpUser(email, password);
    
                const {user} = userCredential;
                // const {email, uid, emailVerified, ...rest} = user;

               await writeData('users',  user.uid, {
                    firstName,
                    lastName,
                    email
                });

               
                setSubmitting(false);
                return history.push('/');
                
            }catch(err){
    
                const {code, message} = err;
    
                console.log({code, message});
                
                setErrors({submit: message});
                
    
            }

        }

        setSubmitting(false);

    }

    return(
        <Container>
            
            <Row
            extraClasses="justify-content-center"
            >
                <Column
                defColumn="col-md-6 p-4 border border-primary shadow-lg rounded-3"
                >
                
                    <SignUpForm
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    password={password}
                    confirmPassword={confirmPassword}
                    errors={errors}
                    submitting={submitting}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setConfirmPassword={setConfirmPassword}
                    onSubmit={onSubmit}
                    />
                </Column>
            </Row>

            <Row
            extraClasses="justify-content-center mt-2"
            >
                <Column
                extraClasses="text-center"
                >
                    <h5>Already have an account?</h5>
                    <Link
                    to="/login"
                    className="fs-5 fw-bolder"
                    >
                        Log In
                    </Link>

                </Column>
            </Row>
        </Container>

    );
}