import {storage} from './index';


export const uploadFile = (file, path)=>{
    return storage.ref().child(path).put(file);
}

export const getDownloadUrl = (path)=>{
    return storage.ref().child(path).getDownloadURL();
}

export const deleteFile = (path)=>{
    return storage.ref().child(path).delete();
}