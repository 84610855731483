import AWS from "aws-sdk";



const lambda = new AWS.Lambda({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: "us-east-1"
});


export const getTptCategories = async ()=>{

    const params = {
        FunctionName: process.env.REACT_APP_AWS_LAMBDA_TPT/* required */,
        Payload: JSON.stringify({
         storeName: process.env.REACT_APP_TPT_STORE_NAME,
         dataType: 'categories'
        })
      };

    const response = await lambda.invoke(params).promise();

    return JSON.parse(response.Payload);
}

export const getTptProducts = async (path='')=>{

    const params = {
        FunctionName: process.env.REACT_APP_AWS_LAMBDA_TPT/* required */,
        Payload: JSON.stringify({
         storeName: process.env.REACT_APP_TPT_STORE_NAME,
         dataType: 'products',
         path
        })
      };

    const response = await lambda.invoke(params).promise();

    return JSON.parse(response.Payload);
}