import { Component } from "react";


import {readData} from "../../utils/firebase/database";
import {Container, Row, Iframe} from "../bootstrapComponents";
import SEO from "../seo";




export default class PlayGame extends Component {

    state ={
        link: null,
        title: null,
        category: null,
        imageUrl: null
    };

    componentDidMount(){

        const {match} = this.props;

        this.fetchGameData(match.params.gameId);
    }

    fetchGameData = async(id)=>{
       

        const gameData = await readData('games', id).then(data=>data.val()).catch(err=>{
            console.log(err);
            
            });

        this.setState({
            link: gameData.url,
            title: gameData.title,
            category: gameData.category,
            imageUrl: gameData.imageUrl
        });
    }


    render(){
        const {link, title, category} = this.state;

        return (
            <Container>
                <SEO
                title={title}
                metaData={[
                    {
                        name: "description",
                        content:`${title} Math Game`
                    },
                    {
                        name: "keywords",
                        content:`${title}, ${category}, Math Games, Fun Games, Middle School Math`
                    }
                    
                ]}

               
                />
                <Row 
                extraClasses="text-center"
                >
                    <h2>{title}</h2>
                    <Iframe
                    link={link}
                    title={title}
                    />
                </Row>
            </Container>
        );
    }

};