import {Helmet} from "react-helmet";

import SeoImage from "../../images/nav-logo.png";

export default function SEO(props){

    const {title="Welcome", metaData=[], socialData=[]} = props;

    

    return(
        <Helmet>

            <title>{`${title} - Mrs. Tut's Math Class`}</title>

            <meta name="description" content="Mrs. Tut's Math Class is creating highly engaging educational Math activities and worksheets which are available for sale on Teachers Pay Teachers, and sharing her insights and experiences from time to time on her blog." />

            <meta name="keywords" content="Math Activities, Math Worksheets, Middle School Math, High School Math, Teachers Pay Teachers" />

            <meta property="og:title" content="Mrs. Tut's Math Class" />
            <meta property="og:description" content="Mrs. Tut's Math Class is creating highly engaging educational Math activities and worksheets which are available for sale on Teachers Pay Teachers, and sharing her insights and experiences from time to time on her blog." />
            <meta property="og:site_name" content="Mrs. Tut's Math Class" />
            <meta property="og:image" content={SeoImage} />

            {
                metaData.map((data, index)=><meta key={index+1} name={data.name} content={data.content} />)
                
            }

            {
                socialData.map((data, index)=><meta key={metaData.length+index} property={data.property} content={data.content} />)
                
            }


        </Helmet>
    )
};