import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

export const Container = ({extraClasses="", ...props})=>(
    <div
    className={`container ${extraClasses}`}
    {...props}
    >
        {props.children}
    </div>
);

export const Row = ({extraClasses="", ...props})=>(
    <div
    className={`row ${extraClasses}`}
    {...props}
    >
        {props.children}
    </div>
);

export const Column = ({extraClasses="", defColumn="col", ...props})=>(
    <div
    className={`${defColumn} ${extraClasses}`}
    >
        {props.children}
    </div>
);

export const Card = (props)=>(
    <div className="card bg-light h-100" style={{width: "100%"}}>
        <img src={props.image} className="card-img-top" alt={props.title} />
        <div className="card-body d-flex flex-column justify-content-between">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text">{props.description}</p>
            <a href={props.link} target="_blank" rel="noreferrer" className="btn btn-lightpink">VIEW</a>
        </div>
    </div>
);

export const ResourceCard = (props)=>(
  <div className="card bg-light h-100" style={{width: "100%"}}>
      <img src={props.imageUrl} className="card-img-top" alt={props.title} />
      <div className="card-body d-flex flex-column justify-content-between">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
          <a href={props.url} onClick={()=>props.incrementClickCount(props.id)} target="_blank" rel="noreferrer" className="btn btn-lightpink">VIEW</a>
          
      </div>
      {
        props.isOwner
        ?
        <p className="text-center" >{props.clickCount} times {props.urlType==='link' ? 'clicked' : 'downloaded'}</p>
        :
        null
      }
      {
        props.isOwner
        ?
        <p 
        className="text-center text-danger text-decoration-underline" 
        style={{cursor: 'pointer'}}
        onClick={()=>{
          if(window.confirm("Are you sure you want to delete this?")){
            props.handleDelete(props.resource);
          }
        }}
        >
          Delete
        </p>
        :
        null
      }
  </div>
);

export const GameCard = (props)=>(
  <div className="card bg-light h-100" style={{width: "100%"}}>
      <img src={props.imageUrl} className="card-img-top" alt={props.title} />
      <div className="card-body d-flex flex-column justify-content-between">
          <h5 className="card-title">{props.title}</h5>
          
          {
            props.urlType === 'iframe'
            ?
            <Link to={`/games/${props.id}`} className="btn btn-lightpink">PLAY</Link>
            :
            <a href={props.url} target="_blank" rel="noreferrer" className="btn btn-lightpink">PLAY</a>
          }
          
      </div>
  </div>
);



export const HorCard = (props)=>(
    <div className="card mb-3" style={{maxWidth: '540px'}}>
        <div className="row g-0">
            <div className="col-md-4">
                <img src={props.image} alt={props.title} />
            </div>
            <div className="col-md-8">
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    <p className="card-text">{props.description}</p>
                    <a href={props.link} target="_blank" rel="noreferrer" className="btn btn-lightpink">VIEW</a>
                </div>
            </div>
        </div>
    </div>
);

export const CategoryDropDown = (props)=>(
<div className="dropdown my-3 d-flex justify-content-center">
  <button className="btn btn-lightpink btn-lg w-75 dropdown-toggle"  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
    {props.selectedCategory.title || "Categories"}
  </button>

  <ul className="dropdown-menu"  aria-labelledby="dropdownMenuLink">
    {
        props.categories.filter(category=>category.link!==props.selectCategory.link).map((category, index)=><li key={index+1} ><button className="dropdown-item" onClick={()=>props.selectCategory(category)}>{category.title}</button></li>)
    }
    
  </ul>
</div>
);

export const BlogDropDown = (props)=>(
  <div className="dropdown my-2 d-flex justify-content-center">
    <button className="btn btn-lightpink btn-md w-75 dropdown-toggle text-truncate"  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      {props.selectedBlog.title || props.defaultTitle}
    </button>
  
    <ul className="dropdown-menu"  aria-labelledby="dropdownMenuLink">
      {
          props.blogs.filter(blog=>blog.id!==props.selectedBlog.id).map((blog, index)=><li key={index+1} ><button className="dropdown-item"  onClick={()=>props.selectBlog(blog)}>{blog.title}</button></li>)
      }
      
    </ul>
  </div>
  );

export const GamesCategoryDropDown = (props)=>(
  <div className="dropdown my-3 d-flex justify-content-center">
    <button className="btn btn-lightpink btn-lg w-75 dropdown-toggle"  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      {props.selectedCategory.name || "Categories"}
    </button>
  
    <ul className="dropdown-menu"  aria-labelledby="dropdownMenuLink">
      {
          props.categories.filter(category=>category.value!==props.selectCategory.value).map((category, index)=><li key={index+1} ><button className="dropdown-item"  onClick={()=>props.selectCategory(category)}>{category.name}</button></li>)
      }
      
    </ul>
  </div>
  );

  export const ResourcesCategoryDropDown = (props)=>(
    <div className="dropdown my-3 d-flex justify-content-center">
      <button className="btn btn-lightpink btn-lg w-75 dropdown-toggle"  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        {props.selectedCategory.name || "Categories"}
      </button>
    
      <ul className="dropdown-menu"  aria-labelledby="dropdownMenuLink">
        {
            props.categories.filter(category=>category.value!==props.selectCategory.value).map((category, index)=><li key={index+1} ><button className="dropdown-item"  onClick={()=>props.selectCategory(category)}>{category.name}</button></li>)
        }
        
      </ul>
    </div>
    );

export const SignUpForm = (props)=>(
    <form className="row g-3" onSubmit={props.onSubmit}>
        <div
        className="col-12 text-center"
        >
          
            <h3><FontAwesomeIcon icon={faUserPlus} /> SIGN UP</h3>

        </div>
  <div className="col-md-6">
    <label htmlFor="first_name" className="form-label">First Name</label>
    <input 
    name="first_name" 
    type="text" 
    className="form-control" 
    id="first_name" 
    placeholder="John"
    value={props.firstName}
    onChange={(e)=>props.setFirstName(e.target.value.trim())}
    required
    />
  </div>
  <div className="col-md-6">
    <label htmlFor="last_name" className="form-label">Last Name</label>
    <input 
    name="last_name" 
    type="text" 
    className="form-control" 
    id="last_name" 
    placeholder="Doe" 
    value={props.lastName}
    onChange={(e)=>props.setLastName(e.target.value.trim())}
    required
    />
  </div>
 
  <div className="col-12">
    <label htmlFor="email" className="form-label">Email</label>
    <input 
    name="email" 
    type="email" 
    className="form-control" 
    id="email" 
    placeholder="example@example.com"
    value={props.email}
    onChange={(e)=>props.setEmail(e.target.value.trim())}
    required
    />
  </div>
  <div className="col-md-6">
    <label htmlFor="password" className="form-label">Password</label>
    <input 
    name="password" 
    type="password" 
    className="form-control" 
    id="password"
    value={props.password}
    onChange={(e)=>props.setPassword(e.target.value.trim())}
    required
    />

    {
      props.errors.password
      ?
      <div className="input-error">
        {props.errors.password}
      </div>
      :
      null
    }
    
  </div>
  <div className="col-md-6">
    <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
    <input 
    name="confirm_password" 
    type="password" 
    className="form-control" 
    id="confirm_password"
    value={props.confirmPassword}
    onChange={(e)=>props.setConfirmPassword(e.target.value.trim())}
    required
    />
    
  </div>
 
  
  
  <div className="col-12 d-flex flex-column justify-content-center align-items-center">
    <button type="submit" className="btn btn-lightpink btn-lg w-50" disabled={props.submitting}>
      {
      props.submitting
      ?
      <div className="spinner-border text-white" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      :
      "SUBMIT"
      }
    </button>
    {
      props.errors.submit
      ?
      <div className="input-error text-center">
        {props.errors.submit}
      </div>
      :
      null
    }
  </div>
</form>
);

export const LogInForm = (props)=>(
  <form className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3><FontAwesomeIcon icon={faSignInAlt} /> LOG IN</h3>

      </div>


<div className="col-12">
  <label htmlFor="email" className="form-label">Email</label>
  <input 
  name="email" 
  type="email" 
  className="form-control" 
  id="email" 
  placeholder="example@example.com"
  value={props.email}
  onChange={(e)=>props.setEmail(e.target.value.trim())}
  required
  />
</div>
<div className="col-12">
  <label htmlFor="password" className="form-label">Password</label>
  <input 
  name="password" 
  type="password" 
  className="form-control" 
  id="password"
  value={props.password}
  onChange={(e)=>props.setPassword(e.target.value.trim())}
  required
  />

  {
    props.errors.password
    ?
    <div className="input-error">
      {props.errors.password}
    </div>
    :
    null
  }
  
</div>

<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn-lg w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    "LOG IN"
    }
  </button>
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      {
        props.errors.submit.code === "auth/wrong-password" || props.errors.submit.code === "auth/too-many-requests"
        ?
        <Link
        to="/forgot-password"
        >
        Forgot Password?
        </Link>
        :
        null
      }
    </div>
    :
    null
  }
</div>
</form>
);

export const ForgotPasswordForm = (props)=>(
  <form className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3>RESET PASSWORD</h3>

      </div>


<div className="col-12">
  <label htmlFor="email" className="form-label">Email</label>
  <input 
  name="email" 
  type="email" 
  className="form-control" 
  id="email" 
  placeholder="example@example.com"
  value={props.email}
  onChange={(e)=>props.setEmail(e.target.value.trim())}
  required
  />
</div>

<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn-lg w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    "SUBMIT"
    }
  </button>
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      
    </div>
    :
    null
  }
  
</div>
</form>
);

export const NewResourceForm = (props)=>(
  <form id="NewResourceForm" className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3>NEW RESOURCE</h3>

      </div>


    <div className="col-12">
      <label htmlFor="title" className="form-label fw-bold">Title</label>
      <input 
      name="title" 
      type="text" 
      className="form-control" 
      id="title" 
      placeholder="Resource title..." 
      value={props.title}
      onChange={(e)=>props.setTitle(e.target.value)}
      required
      />

{
    props.errors.title
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.title}</p>
      
    </div>
    :
    null
  }
    </div>

    <div className="col-12">
      <label htmlFor="description" className="form-label fw-bold">Description</label>
      <textarea 
      name="description" 
      rows="4"
      className="form-control" 
      id="description" 
      placeholder="Resource description..." 
      value={props.description}
      onChange={(e)=>props.setDescription(e.target.value)}
      required
      />

{
    props.errors.description
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.description}</p>
      
    </div>
    :
    null
  }
    </div>

    {
      props.type==="link"
      ?
      <div className="col-12">
        <label htmlFor="url" className="form-label fw-bold">Link Url</label>
        <input 
        name="url" 
        type="text" 
        className="form-control" 
        id="url" 
        placeholder="https://......" 
        value={props.url}
        onChange={(e)=>props.setUrl(e.target.value.trim())}
        required
        />

        {
          props.errors.url
          ?
          <div className="text-center">
            <p className="input-error" >{props.errors.url}</p>
            
          </div>
          :
          null
        }
      </div>
      :
      <div className="col-12">
        <label htmlFor="resource-file" className="form-label fw-bold">Upload a File</label>
            <input 
            name="resource-file" 
            type="file"
            accept="*"
            className="form-control" 
            id="resource-file" 
            // placeholder="https://......" 
            // value={props.url}
            onChange={()=>props.setFile(document.getElementById('resource-file').files[0])}
            required
            />
          {
          props.errors.file
          ?
          <div className="text-center">
            <p className="input-error" >{props.errors.file}</p>
            
          </div>
          :
          null
          }
      </div>
    }

    

   <div  className="col-12">
   <div className="form-check">
      <input className="form-check-input" type="radio" name="linkRadio" id="linkradio" onChange={(e)=>props.setType('link')} checked={props.type==='link'} />
      <label className="form-check-label" hmtlFor="linkradio">
        Link
      </label>
    </div>

    <div className="form-check">
      <input className="form-check-input" type="radio" name="iframeRadio" id="iframeradio" onChange={(e)=>props.setType('file')} checked={props.type==='file'} />
      <label className="form-check-label" hmtlFor="iframeradio">
      File
      </label>
    </div>
   </div>

   <div className="col-12">
   <label htmlFor="image" className="form-label fw-bold">Upload an Image</label>
      <input 
      name="image" 
      type="file"
      accept="image/*"
      className="form-control" 
      id="resource-image" 
      // placeholder="https://......" 
      // value={props.url}
      onChange={()=>props.setImage(document.getElementById('resource-image').files[0])}
      required
      />
    {
    props.errors.image
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.image}</p>
      
    </div>
    :
    null
    }
   </div>

    <div className="col">
      <label hmtlFor="categorySelect" className="form-label fw-bold">Select a category</label>
      <select className="form-select form-select mb-3" aria-label=".form-select" onChange={e=>props.setCategory(e.target.value)} required>
        <option value='' selected={!props.category}>Choose one</option>
        {props.categories && props.categories.map(category=><option key={category.value} value={category.value} selected={props.category===category.value}>{category.name}</option>)}
      </select>
      {
    props.errors.category
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.category}</p>
      
    </div>
    :
    null
  }
    </div>
    

<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn-lg w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    "SUBMIT"
    }
  </button>
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      
    </div>
    :
    null
  }
</div>
</form>
);

export const NewResourceCategory = (props)=>(
  <form className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3>{props.editMode ? 'Edit Category' : 'Add a Category'}</h3>

      </div>

      


    <div className="col-12">
      <label htmlFor="newCategory" className="form-label fw-bold">Name</label>
      <input 
      name="newCategory" 
      type="text" 
      className="form-control" 
      id="newCategory" 
      placeholder="Category Name..." 
      value={props.categoryName}
      onChange={(e)=>props.setCategoryName(e.target.value)}
      required
      />

{
    props.errors.categoryName
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.categoryName}</p>
      
    </div>
    :
    null
  }
    </div>

    <div className="col">
        <label hmtlFor="topicSelect" className="form-label fw-bold">Select a topic</label>
        <select className="form-select form-select mb-3" aria-label=".form-select" value={props.mainTopic} onChange={e=>props.setTopic(e.target.value)} required>
          <option value='' style={{display: 'none'}} >Choose one</option>
          <option value="algebra" >Algebra</option>
          <option value="geometry" >Geometry</option>
        </select>
        {
          props.errors.mainTopic
          ?
          <div className="text-center">
            <p className="input-error" >{props.errors.mainTopic}</p>
            
          </div>
          :
          null
        }
      </div>

    
<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    props.editMode
    ?
    "SAVE"
    :
    "SUBMIT"
    }
  </button>

  {
    props.editMode
    ?
    <button className="btn btn-danger btn-md w-50 mt-2 fs-6" onClick={()=>props.cancelEdit()}>
    CANCEL
  </button>
    :
    null
  }
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      
    </div>
    :
    null
  }
  
</div>
</form>
);

export const NewGameForm = (props)=>(
  <form id="NewGameForm" className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3>NEW GAME</h3>

      </div>


    <div className="col-12">
      <label htmlFor="title" className="form-label fw-bold">Title</label>
      <input 
      name="title" 
      type="text" 
      className="form-control" 
      id="title" 
      placeholder="Game title..." 
      value={props.title}
      onChange={(e)=>props.setTitle(e.target.value)}
      required
      />

{
    props.errors.title
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.title}</p>
      
    </div>
    :
    null
  }
    </div>

    <div className="col-12">
      <label htmlFor="url" className="form-label fw-bold">{props.type==="link" ? 'Link' : 'iFrame'} Url</label>
      <input 
      name="url" 
      type="text" 
      className="form-control" 
      id="url" 
      placeholder="https://......" 
      value={props.url}
      onChange={(e)=>props.setUrl(e.target.value.trim())}
      required
      />

{
    props.errors.url
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.url}</p>
      
    </div>
    :
    null
  }
    </div>

   <div  className="col-12">
   <div className="form-check">
      <input className="form-check-input" type="radio" name="linkRadio" id="linkradio" onChange={(e)=>props.setType('link')} checked={props.type==='link'} />
      <label className="form-check-label" hmtlFor="linkradio">
        Link
      </label>
    </div>

    <div className="form-check">
      <input className="form-check-input" type="radio" name="iframeRadio" id="iframeradio" onChange={(e)=>props.setType('iframe')} checked={props.type==='iframe'} />
      <label className="form-check-label" hmtlFor="iframeradio">
      iFrame
      </label>
    </div>
   </div>

   <div className="col-12">
   <label htmlFor="image" className="form-label fw-bold">Upload an Image</label>
      <input 
      name="image" 
      type="file"
      accept="image/*"
      className="form-control" 
      id="game-image" 
      // placeholder="https://......" 
      // value={props.url}
      onChange={()=>props.setImage(document.getElementById('game-image').files[0])}
      required
      />
    {
    props.errors.image
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.image}</p>
      
    </div>
    :
    null
    }
   </div>

    <div className="col">
      <label hmtlFor="categorySelect" className="form-label fw-bold">Select a category</label>
      <select className="form-select form-select mb-3" aria-label=".form-select" onChange={e=>props.setCategory(e.target.value)} required>
        <option value='' selected={!props.category}>Choose one</option>
        {props.categories && props.categories.map(category=><option key={category.value} value={category.value} selected={props.category===category.value}>{category.name}</option>)}
      </select>
      {
    props.errors.category
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.category}</p>
      
    </div>
    :
    null
  }
    </div>
    

<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn-lg w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    "SUBMIT"
    }
  </button>
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      
    </div>
    :
    null
  }
</div>
</form>
);

export const NewGameCategory = (props)=>(
  <form className="row g-3" onSubmit={props.onSubmit}>
      <div
      className="col-12 text-center"
      >
          <h3>{props.editMode ? 'Edit Category' : 'Add a Category'}</h3>

      </div>


    <div className="col-12">
      <label htmlFor="newCategory" className="form-label fw-bold">Name</label>
      <input 
      name="newCategory" 
      type="text" 
      className="form-control" 
      id="newCategory" 
      placeholder="Category Name..." 
      value={props.categoryName}
      onChange={(e)=>props.setCategoryName(e.target.value)}
      required
      />

{
    props.errors.categoryName
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.categoryName}</p>
      
    </div>
    :
    null
  }
    </div>

    
<div className="col-12 d-flex flex-column justify-content-center align-items-center">
  <button type="submit" className="btn btn-lightpink btn w-50" disabled={props.submitting}>
    {
    props.submitting
    ?
    <div className="spinner-border text-white" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    :
    props.editMode
    ?
    "SAVE"
    :
    "SUBMIT"
    }
  </button>

  {
    props.editMode
    ?
    <button className="btn btn-danger btn-md w-50 mt-2 fs-6" onClick={()=>props.cancelEdit()}>
    CANCEL
  </button>
    :
    null
  }
  {
    props.errors.submit
    ?
    <div className="text-center">
      <p className="input-error" >{props.errors.submit.message}</p>
      
    </div>
    :
    null
  }
  
</div>
</form>
);



export const Accordion = (props)=>(
  <div className="accordion" id={`accordion${props.indexAdjust}`}>
    {
      props.categories.map((category, i)=>{
        let index = i+props.indexAdjust;

        return (
          <div key={index} className="accordion-item" style={{background: `linear-gradient(90deg, ${props.backgroundColors[i][0]} 0%, ${props.backgroundColors[i][1]} 64%)`}}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                {category.name}
              </button>
            </h2>
            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent={`#accordion${props.indexAdjust}`}>
              <div className="accordion-body">
                {/* <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
              <GamesList
              games={props.games[category.value]}
              />
              </div>
            </div>
          </div>
        );
      })
    }
  </div>
);

const GamesList = (props)=>{
  const sliceIndex = Math.ceil(props.games.length/2);
  return(
    <Row>
      <Column>
        <FlushList
        items={props.games.slice(0, sliceIndex)}
        />
      </Column>
      <Column>
      <FlushList
        items={props.games.slice(sliceIndex)}
        />
      </Column>
    </Row>
  );
};

const FlushList = (props)=>(
  <ul className="list-group list-group-flush">
    {
      props.items.map(item=>item.type==="iframe" ? <Link to={`/games/${item.id}`}><li className="list-group-item">{item.title}</li></Link> : <a href={item.url} target="_blank" rel="noreferrer"><li className="list-group-item">{item.title}</li></a>)
    }
  </ul>
);

export const Iframe = (props)=>(
  <div className="ratio ratio-16x9">
    <iframe src={props.link} title={props.title} allowFullScreen></iframe>
  </div>
);