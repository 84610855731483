
import {database} from './index';

export const writeData = (tableName="", id, payload)=>{

  return  database.ref(`${tableName}/` + id).set(payload);
}

export const updateData = (updates)=>{

  return  database.ref().update(updates);
}

export const readData = (tableName, id='')=>{
  return database.ref(`/${tableName}/` + id).once('value');
}

export const getData = (tableName, id='')=>{
  return database.ref().child(tableName).child(id).get();
};

export const deleteData = (tableName="", id)=>{

  return  database.ref(`${tableName}/` + id).remove();
}

export const getUID = (tableName)=>{
  return database.ref().child(tableName).push().key;
};
