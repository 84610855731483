import BodyEditor from './reactDraft';

const NewBlogForm = (props)=>{

    return (
        <form onSubmit={props.onSubmit} >
            <div className="mb-3">
                <label htmlFor="blogTitle" className="form-label fw-bold fs-4">Title</label>
                <input
                value={props.title}
                onChange={(e)=>props.setTitle(e.target.value)}
                placeholder="New Blog Title..."
                type="text" 
                className="form-control" 
                id="blogTitle" 
                aria-describedby="blogTitleHelp" 
                required
                />

                {
                props.errors.title
                ?
                <div className="input-error">
                    {props.errors.title}
                </div>
                :
                null
                }
                
            </div>

            <div className="mb-3">
                <label htmlFor="blogBody" className="form-label fw-bold fs-4">Body</label>
                <div className="w-100 form-control" id="blogBody">
                    <BodyEditor
                    initialContent={props.initialContent}
                    updateContent={(content)=>props.setBody(content)}
                    blogId={props.blogId}
                    />
                </div>
                {
                props.errors.body
                ?
                <div className="input-error">
                    {props.errors.body}
                </div>
                :
                null
                }
            </div>

            {/* language selection starts */}
            <p className="form-label fw-bold fs-4">Choose a language:</p>
            <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="language" id="lang-english" onChange={(e)=>props.setLanguage('english')} checked={props.language==='english'} />
            <label className="form-check-label" htmlFor="lang-english">
                English
            </label>
            </div>
            <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="language" id="lang-turkish" onChange={(e)=>props.setLanguage('turkish')} checked={props.language==='turkish'} />
            <label className="form-check-label" htmlFor="lang-turkish">
                Turkish
            </label>
            </div>

            {/* language selection ends */}
            
            <div className="mb-5 d-flex justify-content-center">
                
                <button type="submit" className="btn btn-lightpink btn-lg w-25 fw-bold fs-3" disabled={props.submitting}>
                {
                props.submitting
                ?
                <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                :
                props.editMode ? "SAVE" : "POST"
                }
                </button>
                {
                props.errors.submit
                ?
                <div className="input-error">
                    {props.errors.submit}
                </div>
                :
                null
                }

            </div>
        </form>
    );
};


export default NewBlogForm;