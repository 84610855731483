import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


import {analytics} from "./utils/firebase";
import {authObserver} from "./utils/firebase/auth";
import {readData} from "./utils/firebase/database";

require('dotenv').config();

//initialize analytics

analytics.logEvent("new_visit");

//initialize firebase auth observer

authObserver(async function(user) {
  if (user) {
    // User is signed in.

    let userData = null;

   try {
    const theUser = await readData('users', user.uid);

    userData = {
      ...theUser.val(),
      uid: user.uid
    };
   }catch(err){
     
   }

  
    
    ReactDOM.render(
      <React.StrictMode>
        <App authUser={userData} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    // No user is signed in.
    
    ReactDOM.render(
      <React.StrictMode>
        <App authUser={null} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
});


// getTptData().then(data=>console.log(JSON.parse(data.Payload),'lambdaDATA')).catch(err=>console.log(err,'ERRR_LAMBDA'))



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
