import {
    Link
  } from "react-router-dom";


import SEO from "../seo";
import Banner from "../../images/banner.png";
import ReadBlog from "../../images/readBlog.png";
import VisitShop from "../../images/visitStore.png";
import PlayGames from "../../images/playGames.png";
import BioPic from "../../images/bioPicture.png";
import InstagramFeed from "../../utils/instagram";
import SubscribeForm from "../../utils/mailchimp";

import {Container, Row, Column} from "../bootstrapComponents";


export default function Home(props){

    const infoCards =[
        {
            image: ReadBlog,
            title: 'Read The Blog',
            texts: [
                'Check my blog to follow my teaching journey.',
                'You will find math teaching strategies, technology tips and tricks, my favorite activities, and more...'
            ],
            button: {
                link: `/blog/${props.initialBlogId}`,
                text: 'Read'
            }
        },
        {
            image: PlayGames,
            title: 'Play Math Games',
            texts: [
                'Math is more fun with games!',
                'Check out the interactive online games I prepared for you.',
                'Play them with your students to practice, review, or just for fun.',
                'Enjoy!'
            ],
            button: {
                link: '/games',
                text: 'Play'
            }
        },
        {
            image: VisitShop,
            title: 'Visit My Store',
            texts: [
                'Lesson Plans, Fun Games, Digital Activities...',
                'What are you looking for?',
                'My store can help.',
                'Check it out.'
            ],
            button: {
                link: '/tpt-store',
                text: 'Visit'
            }
        },
    ];

    return(
        <Container>
            
            <SEO
            title="Welcome"
            metaData={[
                {
                    name: "description",
                    content:"Mrs. Tut's Math Class is creating highly engaging educational Math activities and worksheets which are available for sale on Teachers Pay Teachers, and sharing her insights and experiences from time to time on her blog."
                },
                {
                    name: "keywords",
                    content:"Math Activities, Math Worksheets, Middle School Math, High School Math, Teachers Pay Teachers"
                }
                
            ]}
            />

            <img src={Banner} alt="Banner" width="100%"/>

            {/* <InstagramFeed extraClassNames="d-none d-md-block" /> */}

            <Row extraClasses="mt-5">
                <Column defColumn="col-12 col-md-8">

                {infoCards.map(infoCard=><HorizontalCard image={infoCard.image} title={infoCard.title} texts={infoCard.texts} button={infoCard.button}  />)}
                
                </Column>

                <Column defColumn="col-4 d-none d-md-block">

                    <div className="card w-100" style={{width: "18rem"}}>
                        <img src={BioPic} className="card-img-top" alt="Elif Bio" />
                        {/* <h4 className="card-title text-center">Elif Ay</h4> */}
                        <div className="card-body">
                            <p className="card-text text-center">Hey there! I am a first-year high school math teacher with five years of teaching experience for second through seventh graders.  I believe math is a fun subject to teach and learn with engaging lessons and the right strategies. I love trying new methods and using technology and games in my classroom. I will share my learning and teaching experience in this blog. I hope you will like it.</p>
                        </div>
                    </div>
                    <div
                    className="p-4 card mt-2 shadow-sm rounded-3"
                    >
                    <SubscribeForm />

                    </div>
                
                </Column>
            </Row>

            <div
            className="d-block d-md-none p-4 card mt-2 shadow-sm rounded-3"
            >
                <SubscribeForm />

            </div>
            <InstagramFeed extraClassNames="d-block d-md-none" />
            

        </Container>
    );
}


const HorizontalCard = (props)=>(
<div className="card mb-3" >
  <div className="row g-0">
    <div className="col-md-4">
      <img src={props.image} className="img-fluid rounded-start" alt={props.title} />
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h2 className="card-title">{props.title}</h2>
        
        <p className="card-text fs-3">{props.texts.join(' ')}</p>
        
      <Link to={props.button.link} className="btn btn-lightpink fs-5" >{'Click Here' || props.button.text}</Link>
      </div>
    </div>
  </div>
</div>
);